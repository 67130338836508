const secrets = {
  bingKey: 'AjIaSgFN8xyEZGb_05yAuJj-UedZOciA_yVZ9ST06qkEvq77WNA1nLk9z9kT1Ecw',
  thunderforestKey: 'cf406f30bfc54296a9d573ced1e9d6cf',
  sentryDSN: 'https://363a3eefa37809eb8473d9297c53308c@o4506075867906048.ingest.sentry.io/4506075870068736',
  mapillary4: 'MLY|6550708168342546|dac3fb30b2b2807ef0355871f7788ffc',
  flickr: '007e6fdace9f9f14d40f6f09364c75b7',
  google: 'AIzaSyC28kpyt9WOS14ST7LkqojJ8m1CvRh2fWg',
  mapyCz: 'C9JG81-wcyJJwbFpOQxfk7ma5DmTzaOxzlDYab85RF4',
  openRouteKey: '5b3ce3597851110001cf624870fb1f5a500340c295bf8ae020110ec6'
};

export default secrets;
